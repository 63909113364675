<template>
  <div class="menu-nav">
    <div>
      <div class="menu-logo paddleftB" @click="home">
        <img src="../../static/images/home/logo-su.png" />
      </div>
      <div class="menu-tab">
        <div
          v-for="(item, index) in menu"
          :key="index + 'n'"
          class="size16"
          @mouseover="mouseOver($event, item.name, 1)"
          @mouseleave="mouseLeave($event, item.name)"
        >
          <router-link :to="{ path: item.path }" v-if="item.name != 'a'">
            <div
              :class="{ 'menu-tab__active': $route.meta.active == item.name }"
              :data-id="$route.meta.active"
            >
              <a class="menu-tab__a paddleftB">{{ item.title }}</a>
            </div>
          </router-link>
          <div v-else @click="biness">
            <a class="menu-tab__a paddleftB">{{ item.title }}</a>
          </div>
        </div>
      </div>
    </div>
    <div class="menu-bottom size14 paddleftB">
      <div class="menu-bottom__icon">
        <img src="../../static/images/home/phone-icon.png" class="phone" />
        <span class="phone-font">400-160-8899</span>
      </div>
      <div class="menu-bottom__icon">
        <img src="../../static/images/home/email-icon.png" class="email" />
        <span class="email-font">kf@jachx.com</span>
      </div>
      <!-- <div class="menu-bottom__icon">
        <span class="email-font" style="{width:200px}">@2018-{{time}} 合肥和行科技有限公司 <br> All Rights Reserved {{time}} 和行约车</span
        >
      </div>
      <div class="menu-bottom__icon">
        <img src="../../static/images/home/bottomlogo.png" class="message" />
        <span class="email-font">皖公网安备 34019102000434号 <br> 皖ICP备18015830号-1 </span>
      </div> -->
    </div>
    <!-- 抽屉 -->
    <div
      class="menu-drawer"
      :style="[drawerStyle]"
      @mouseover="mouseOver($event, routerName, 2)"
      @mouseleave="mouseLeave($event, routerName)"
    >
      <div class="menu-logo paddleftB" style="opacity: 0" v-show="drawerShow">
        <img src="../../static/images/home/logo-su.png" />
      </div>
      <div class="menu-drawer__zw"></div>
      <div v-show="drawerShow" class="menu-drawer__ul menu-drawer__top">
        <div v-for="(item, index) in lieb" :key="index + 'p'">
          <router-link
            :to="{ path: item.path }"
            @click.native="getClick()"
          >
            <div
              class="menu-drawer__li size16"
              :class="{ 'menu-drawer__active': $route.meta.ac == item.name }"
            >
              <a class="paddleftB">{{ item.title }}</a>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      menu: [
        { title: "首页", path: "/", name: "index" },
        {
          title: "出行服务",
          path: "/travelService/express",
          name: "travelService",
        },
        { title: "软件业务", path: "/", name: "a" },
        {
          title: "司机招募",
          path: "/driverRecruitment",
          name: "driverRecruitment",
        },
        {
          title: "关于我们",
          path: "/aboutSelf/companyProfile",
          name: "aboutSelf",
        },
      ],
      drawerStyle: {},
      drawerShow: false,
      menuCh: [
        { title: "快车", path: "/travelService/express", name: "ex" },
        { title: "顺风车", path: "/travelService/hitchRide", name: "hi" },
        { title: "语音的士", path: "/travelService/voiceTaxi", name: "vo" },
        { title: "企业用车", path: "/travelService/businessCar", name: "bs" },
      ],
      menuSelf: [
        { title: "企业简介", path: "/aboutSelf/companyProfile", name: "jj" },
        {
          title: "企业党建",
          path: "/aboutSelf/companyPartyBuilding",
          name: "dj",
        },
        { title: "董事长寄语", path: "/aboutSelf/message", name: "jy" },
        { title: "社会责任", path: "/aboutSelf/social", name: "zr" },
        { title: "联系我们", path: "/aboutSelf/contact", name: "lx" },
      ],
      isdrawer: true,
      lieb: [],
      routerName: "",
      // time:""
    };
  },
  // mounted() {
  //   this.nowtime();
  // },
  methods: {
    home() {
      this.$router.push("/");
    },
    biness() {
      window.open("https://e.jachx.com/#/");
    },
    getClick() {
      this.drawerStyle = {
        width: "0",
      };
      this.drawerShow = false;
      this.isdrawer = false;
      setTimeout(() => {
        this.isdrawer = true;
      }, 1000);
    },
    mouseLeave() {
      // // 鼠标离开“抽屉”宽度为0
      this.drawerStyle = {
        width: "0",
      };
      // 鼠标离开“抽屉”不显示
      this.drawerShow = false;
    },
    mouseOver(e, name, type) {
      if (type == 2) {
        this.drawerStyle = {
          width: "60%",
        };
        this.routerName = name;
        this.drawerShow = true;
        // this.lieb = name == "travelService" ? this.menuCh : this.menuSelf;
        this.lieb = name == "aboutSelf" ? this.menuSelf : this.menuCh;
      }
      if (name == "travelService" && this.isdrawer && type == 1) {
        this.drawerStyle = {
          width: "60%",
        };
        // 将name传递给抽屉的routername
        this.routerName = "travelService";
        this.drawerShow = true;
        this.lieb = this.menuCh;
      } else if (name == "aboutSelf" && this.isdrawer && type == 1) {
        this.drawerStyle = {
          width: "60%",
        };
        this.routerName = "aboutSelf";
        this.drawerShow = true;
        this.lieb = this.menuSelf;
      }
    },
    // nowtime() {
    //     let nowDate = new Date();
    //     let date = {
    //         // 获取当前年份
    //         year: nowDate.getFullYear(),
    //     };
    //     this.time = date.year;
    // },
  },
};
</script>
<style lang="less" scoped>
.menu-nav {
  width: 100%;
  float: left;
  position: relative;
  height: 100vh;
  box-shadow: 0px 0px 4px #cccccc;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  z-index: 999;
}

.menu-logo {
  width: 100%;
  margin: 54px 0;
  // padding-left:10%;
  box-sizing: border-box;
}

.menu-logo img {
  width: 151px;
  height: 30px;
  cursor: pointer;
}

.menu-tab {
  margin-top: 25%;
}

.menu-tab div {
  // font-size:16px;
  color: #2d2d39;
  width: 100%;
  height: 54px;
  cursor: pointer;
  position: relative;
  // padding-left:10%;
  box-sizing: border-box;
  line-height: 54px;
  text-align: left;
}

// .menu-tab div .menu-tab__a {
//   // padding-left:10%;
// }
.menu-tab .menu-tab__active a {
  color: #e53e3e;
}

.menu-tab div:hover {
  background-color: #f6f6f6;
}

.menu-tab__active ::before {
  content: "";
  display: block;
  width: 6%;
  background-color: #e53e3e;
  height: 1px;
  position: absolute;
  left: 0;
  top: 26px;
}

.menu-bottom {
  width: 100%;
  // padding-left:10%;
  box-sizing: border-box;
  // position: absolute;
  margin-bottom: 10%;
}

.menu-bottom__icon {
  margin-bottom: 20px;
}

.menu-bottom__icon .phone {
  width: 16px;
  height: 16px;
  margin-right: 20px;
}

.menu-bottom__icon .email {
  width: 16px;
  height: 13px;
  margin-right: 20px;
}

.menu-bottom__icon .message {
  width: 12px;
  height: 12px;
  // margin-right: 20px;
}

.menu-bottom__icon .phone-font {
  // font-size:14px;
  color: #626266;
  font-weight: bold;
}

.menu-bottom__icon .email-font {
  // font-size:14px;
  color: #626266;
}

.paddleftB {
  padding-left: 10%;
}

.size16 {
  font-size: 16px;
}

.size14 {
  font-size: 14px;
}

.menu-drawer {
  width: 0;
  position: absolute;
  top: 0;
  left: 100%;
  background-color: #ffffff;
  height: 100vh !important;
  box-shadow: 0px 0px 6px rgb(228, 228, 228);
  transition: all 0.3s;
  z-index: 88;

  &__li {
    width: 100%;
    height: 54px;
    line-height: 54px;
    cursor: pointer;
    transition: all 0.3s;
    box-sizing: border-box;
    word-break: keep-all;
    /* 不换行 */
    white-space: nowrap;

    /* 不换行 */
    &:hover {
      padding-left: 7%;
      word-break: keep-all;
      /* 不换行 */
      white-space: nowrap;
    }
  }

  &__active {
    padding-left: 7%;
  }

  &__top {
    margin-top: 25%;
  }

  &__zw {
    height: 26px;
  }
}

@media (max-width: 1024px) {
  .size16 {
    font-size: 14px;
  }

  .size14 {
    font-size: 12px;
  }

  .menu-tab {
    margin-top: 25%;
  }

  .paddleftB {
    padding-left: 0;
  }

  .menu-drawer__top {
    margin-top: 25%;
  }
}

@media (min-width: 1024px) {
  .size16 {
    font-size: 14px;
  }

  .size14 {
    font-size: 12px;
  }

  .menu-tab {
    margin-top: 25%;
  }

  .paddleftB {
    padding-left: 10%;
  }

  .menu-drawer__top {
    margin-top: 25%;
  }
}

/*>=1024的设备*/

@media (min-width: 1100px) {
  .size16 {
    font-size: 14px;
  }

  .size14 {
    font-size: 12px;
  }

  .menu-tab {
    margin-top: 25%;
  }

  .paddleftB {
    padding-left: 11%;
  }

  .menu-drawer__top {
    margin-top: 25%;
  }
}

/*>=1100的设备*/
@media (min-width: 1280px) {
  .size16 {
    font-size: 14px;
  }

  .size14 {
    font-size: 12px;
  }

  .menu-tab {
    margin-top: 25%;
  }

  .paddleftB {
    padding-left: 11%;
  }

  .menu-drawer__top {
    margin-top: 25%;
  }
}

/*>=1280的设备*/

@media (min-width: 1366px) {
  .size16 {
    font-size: 14px;
  }

  .size14 {
    font-size: 12px;
  }

  .menu-tab {
    margin-top: 25%;
  }

  .paddleftB {
    padding-left: 13%;
  }

  .menu-drawer__top {
    margin-top: 25%;
  }
}

@media (min-width: 1440px) {
  .size16 {
    font-size: 14px;
  }

  .size14 {
    font-size: 12px;
  }

  .menu-tab {
    margin-top: 25%;
  }

  .paddleftB {
    padding-left: 15%;
  }

  .menu-drawer__top {
    margin-top: 25%;
  }
}

@media (min-width: 1680px) {
  .size16 {
    font-size: 16px;
  }

  .size14 {
    font-size: 14px;
  }

  .menu-tab {
    margin-top: 30%;
  }

  .paddleftB {
    padding-left: 15%;
  }

  .menu-drawer__top {
    margin-top: 30%;
  }
}

@media (min-width: 1920px) {
  .size16 {
    font-size: 18px;
  }

  .size14 {
    font-size: 16px;
  }

  .menu-tab {
    margin-top: 45%;
  }

  .paddleftB {
    padding-left: 20%;
  }

  .menu-drawer__top {
    margin-top: 45%;
  }
}
</style>